<template>
  <div>
    <div class="documents-page">
      <v-card color="#fff" flat>
        <v-card-title class="pt-6 pb-9 px-6">
          <h3>{{ warningHeader }}</h3>
        </v-card-title>
      </v-card>
      <v-card color="#1F6070" flat>
        <v-card-title>
          <h1>What are you looking for?</h1>
        </v-card-title>
      </v-card>
      <div class="pt-6 px-4" style="height: 55vh; overflow-y: auto">
        <loading-circle v-if="loading" />
        <div v-else>
          <v-card
            v-for="item in categoryList"
            :key="item"
            color="#fff"
            flat
            class="mb-4 mx-4"
            style="border-radius: 16px"
            @click="goToLink(item.key)"
          >
            <v-card-title>
              <div v-if="unread(item.value)" class="picker mr-2"></div>
              <h2>{{ item.key }}</h2>
              <v-spacer></v-spacer>
              <img
                width="15px"
                height="30px"
                :src="require('../../assets/svg/right.svg')"
                class="pr-2"
              />
            </v-card-title>
          </v-card>
        </div>
      </div>

      <Notify
        v-if="notification.show"
        :width="containerWidth"
        :type="notification.type"
        no-title
        @close="notification.show = false"
        @consent="notification.show = false"
        :message="notification.message"
      />

      <v-dialog v-model="dialog" persistent width="500">
        <v-card>
          <v-card-title>Upload File</v-card-title>
          <v-card-text>
            <v-file-input v-model="file" label="Select File" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="cancel">Cancel</v-btn>
            <v-btn
              color="#1F6070"
              dark
              @click="convertImageToPdf"
              :loading="uploading"
            >
              Upload
              <v-icon>mdi-upload</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-footer
      height="60px"
      style="
        background-color: transparent;
        margin-bottom: 5px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0px;
      "
    >
      <Btn
        :loading="uploading"
        theme="primary"
        width="90%"
        @click="dialog = true"
      >
        Upload Documents
      </Btn>
    </v-footer>
  </div>
</template>
<script>
import { api } from '../../sharedPlugin';
import LoadingCircle from '@/components/LoadingCircle.vue';
import { toUploadFormat } from '../../utils/files';
import { jsPDF } from 'jspdf';
import Notify from '@/components/global/Notify';
export default {
  components: { LoadingCircle, Notify },
  data: () => ({
    categoryList: [],
    loading: false,
    uploading: false,
    dialog: false,
    file: null,
    category: 0,
    notification: {
      show: false,
      message: null,
      type: 'error'
    },
    warningHeader:
      'As you go through your treatment, there will be many documents and records to keep track of. Everything you and your care team provide is organized here.'
  }),
  methods: {
    getCategoryDocs() {
      this.loading = true;
      api.Documents.getAll()
        .then((res) => {
          this.categoryList = res
            .slice(0, 1000)
            .sort((a, b) => b.created - a.created)
            .groupBy((el) => el.category);

          setTimeout(() => {
            this.$store.dispatch('messages/pullNotificationUnreadCount', true);
          }, 200);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    upload() {
      this.uploading = true;

      toUploadFormat(this.file, true)
        .then((res) =>
          api.Documents.uploadDocument({
            document: res,
            categoryId: this.category,
            filename: this.removeAccents(this.file.name)
          })
        )
        .then((uploadResponse) => {
          this.getCategoryDocs();
          this.dialog = false;
          this.file = null;
          this.notification.show = true;
          this.notification.type = 'success';
          this.notification.message = 'Your document has been uploaded';
        })
        .finally(() => (this.uploading = false));
    },
    removeAccents(str) {
      return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/%20/g, '');
    },
    goToLink(item) {
      this.$router.push(`/documents/list/${item}`);
    },
    cancel() {
      this.dialog = false;
      this.file = null;
    },
    convertImageToPdf() {
      const isValidFilename = (filename) => /^[\x20-\x7E\n]+$/.test(filename);
      try {
        if (!isValidFilename(this.file.name)) {
          this.uploading = false;
          this.notification.show = true;
          this.notification.type = 'error';
          this.notification.message =
            'Your file contains special characters that are not supported by the system, please rename and upload your file without these characters.';
          this.cancel();
        } else if (
          this.file.type == 'image/png' ||
          this.file.type == 'image/jpeg'
        ) {
          this.uploading = true;

          var url = URL.createObjectURL(this.file);
          var img = new Image();

          var doc = null;

          img.addEventListener('load', (e) => {
            if (img.width > img.height) {
              doc = new jsPDF('l', 'mm', [img.width, img.height]);
              doc.addImage(img, 'png', 0, 0, img.width, img.height, '', 'SLOW');
            } else {
              doc = new jsPDF('p', 'mm', [img.width, img.height]);
              doc.addImage(img, 'png', 0, 0, img.width, img.height, '', 'SLOW');
            }

            var newDate = new Date();

            this.convertSuccess(
              doc.output('datauristring'),
              'document_' + newDate.getTime() + '.pdf'
            );
          });

          img.src = url;
        } else if (this.file.type == 'application/pdf') {
          this.upload();
        } else {
          this.uploading = false;
          this.notification.show = true;
          this.notification.type = 'error';
          this.notification.message =
            'File type not supported. Please try again with a PDF, JPG, or PNG file.';
          this.cancel();
        }
      } catch (error) {
        console.log(error);
      }
    },
    convertSuccess(date, name) {
      var base = date.split(',')[1];

      api.Documents.uploadDocument({
        document: base,
        categoryId: this.category,
        filename: name
      })
        .then((uploadResponse) => {
          this.getCategoryDocs();
          this.file = null;
          this.dialog = false;
          this.uploading = false;
          this.notification.show = true;
          this.notification.type = 'success';
          this.notification.message = 'Your document has been uploaded';
        })
        .finally(() => (this.uploading = false));
    },
    unread(item) {
      const containsUnread = item.some(
        (obj) =>
          Object.prototype.hasOwnProperty.call(obj, 'unread') &&
          obj.unread === true
      );

      if (containsUnread) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getCategoryDocs();
  },
  watch: {
    dialog(val) {
      if (val) {
        this.getCategoryDocs();
      }
    }
  }
};
</script>
<style lang="scss">
.documents-page {
  .v-card__title {
    h1 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      color: #000000;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
  .picker {
    background-color: rgb(214, 54, 110);
    border-color: rgb(214, 54, 110);
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>
